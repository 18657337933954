body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  margin: 0;
  padding: 0;
  height: auto;
}

#root {
  transform: none !important;
}

.shell-modal {
  transform: none !important;
}

.shell-modal-container-footer {
  z-index: 1000 !important;
}

.Toastify {
  z-index: 999999999999;
}


.dark .shell-select-container-multiple .shell-select-container-selector .shell-select-container-selection-item {
  color : rgba(255, 255, 255, 0.99) !important
}

.shell-select-container-multiple .shell-select-container-selector .shell-select-container-selection-item {
  color : rgb(52, 52, 52)!important
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

